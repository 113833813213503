<template>
    <v-container fluid fill-height class="bonus-background">
        <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>
                <v-scale-transition>

                <v-card class="elevation-12" v-show="show">
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>Авторизация</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form ref="form" v-model="valid" lazy-validation @keyup.native.enter="submit">
                            <v-text-field prepend-icon="person" name="e-mail" label="E-mail" type="email"
                                          v-model="email" :rules="emailRules" required ref="loginField">
                            </v-text-field>
                            <v-text-field prepend-icon="lock" name="password" label="Password" id="password"
                                          type="password" required v-model="password" :rules="passwordRules">
                            </v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" :disabled="!valid" @click="submit">Войти</v-btn>
                        <!--<v-btn color="primary" :disabled="!valid" type="submit">Login</v-btn>-->
                    </v-card-actions>
                </v-card>
                </v-scale-transition>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
  export default {
    name: 'Signin',

    data() {
      return {
        show: false,
        valid: false,
        email: '',
        password: '',
        emailRules: [
          v => !!v || 'E-mail is required',
          /*v => /.+@.+/.test(v) || 'E-mail must be valid'*/
        ],
        passwordRules: [
          v => !!v || 'Password is required',
          v =>
            v.length >= 6 ||
            'Password must be greater than 6 characters'
        ]
      };
    },

    mounted() {
      this.show = true;
      this.$nextTick(() => this.$refs.loginField && this.$refs.loginField.focus());
      this.checkIsAuthenticated();
    },

    methods: {
      checkIsAuthenticated(){
        //console.log(this.$store.getters.isAuthenticated);
        if (this.$store.getters.isAuthenticated){
          console.log('sign vue checkIs auth ok');
          this.$store.dispatch("postLogin");
        }
        else {//и тут promise cheakAuthUser
          this.checkAuthUser();
        }
      },

      checkAuthUser() {                //to promise variant
        this.$store.dispatch("checkAuth", 'SignIn.vue checkAuthUser()')
          .then( (response) => {
            //this.$router.push('/');
            this.$store.dispatch("postLogin");
            })
          .then(null, function(error){
            console.log('signIn.vue checkAuthUser error');
          });
      },

      submit() {
        const vm = this;

        if (this.$refs.form.validate()) {
          this.$store.dispatch("setLoading", true);
          this.$store.dispatch('signIn', {username: this.email,password: this.password })
            .then(function(response){
              console.log('sign.vue ok: ' + response);
              vm.$store.dispatch("postLogin");
            })
            .then(null, function(error){
              console.log('signIn.vue error: ' + error.message);
              vm.$store.dispatch("showMessage", {text: 'Неправильный логин или пароль!', error: true});
            })
            .finally(() =>
            this.$store.dispatch("setLoading", false)
          );
        }
      }
    }
  };
  /* methods: {
    login () {
        let params = {
            mail: this.email,
            password: this.password
        }
        this.$store.dispatch('login', params).then((response) => {
            this.$router.push({name: 'dashboard', params: {'userId': String(response.data.user_id)}})
        }).catch(() => {
            this.submitsnackbar = true
        })
    }
  }*/
</script>

<style scoped>
</style>
